<template>
  <div id="company-billing-page">

    <CompanyBillingFormModal 
      ref="edit-modal" 
      :item="selected_company_billing" 
      @created="company_billing_created"
      @updated="company_billing_updated" />

    
    <v-tabs background-color="white" color="accent-4" left>
      <v-tab>{{ $t('COMPANY_BILLING.ALL') }}</v-tab>
      <v-tab>{{ $t('SUPPORT.ARCHIVE') }}</v-tab>
      <v-tab>{{ $t('SUPPORT.SUMMARY') }}</v-tab>

      <v-tab-item class="pt-8">
        <CompanyBillingTable 
          :items="company_billings" 
          @create_company_billing_clicked="create_company_billing_clicked"
          @select_company_billing_clicked="select_company_billing_clicked" 
          @delete_company_billing_clicked="delete_company_billing_clicked" />

      </v-tab-item>

      <v-tab-item class="pt-8">

      </v-tab-item>


      <v-tab-item class="pt-8">

        
      </v-tab-item>

      <v-tab-item class="pt-8">
        
      </v-tab-item>


    </v-tabs>

    <div id="wiztr-bottom"></div>

  </div>
</template>
    
<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import CompanyBillingTable from '@/view/pages/ml/company_billing/CompanyBillingTable.vue';
import CompanyBillingFormModal from '@/view/pages/ml/company_billing/CompanyBillingFormModal.vue';



export default {
  name: 'CompanyBillingPage',
  mixins: [ toasts ],
  components: {
    CompanyBillingTable,
    CompanyBillingFormModal,
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'isTHS'])
  },
  async mounted() {
    this.company_billings = await this.load_company_billings();
  },
  data() {
    return {
      company_billings: [],

      selected_company_billing: null
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    company_billing_created(item) {
      this.company_billings.push(item);
    },

    async company_billing_updated(item) {
      this.company_billings = await this.load_company_billings();
    },

    create_company_billing_clicked() {
      this.selected_company_billing = {};

      this.$refs['edit-modal'].show();
    },

    async delete_company_billing_clicked(item) {
      
      try {
        const res = await axios.delete(`/company_billing/${item.id}`);

        if (res.status === 204) {

          this.company_billings = this.company_billings.filter(i => i.id !== item.id);

          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('COMPANY_BILLING.DELETED'));

          return true;
        }
      }
      catch (err) {
        console.error('delete_company_billing_clicked error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_BILLING.UNABLE_DELETE'));

      return false;
    },

    async select_company_billing_clicked(item) {
      this.selected_company_billing = await this.get_company_billing(item.id);

      this.$refs['edit-modal'].show();
    },

    async get_company_billing(id) {
      try {
        const res = await axios.get(`/company_billing/${id}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('get_ticket error', err);
      }
    },


    async load_company_billings() {
      try {
        const res = await axios.get(`/company_billing`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('load_tickets error', err);
      }

      return [];
    },

  }
};
</script>
    