<template>
  <div class="card card-custom card-stretch gutter-b">

    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column pl-2">

      </h3>
      <div class="card-toolbar">
        <a href="#" class="btn btn-primary font-weight-bolder font-size-sm mr-1" @click.prevent="create_billing_clicked"><i
            class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('COMPANY_BILLING.CREATE') }}</a>
      </div>
    </div>

    <div class="card-body pt-0 table-responsive">
      <b-table id="tickets-table" :fields="fields" :items="items" head-variant="light" class='table-striped w-100 mh-100'
        sticky-header :tbody-tr-class="rowClass">
        <template #cell(assigned_user)="row">
          <v-chip style="font-size: 10px;" v-if="row.item.assigned_user === null" color="red" outlined>
            {{ $t('SUPPORT.TICKET.UNASSIGNED') }}
          </v-chip>

          <v-chip style="font-size: 10px;" v-if="row.item.assigned_user !== null" color="green" outlined>
            {{ row.item.assigned_user.email }}
          </v-chip>

        </template>

        <template #cell(status)="row">
          <v-chip style="font-size: 10px;" v-if="row.item.status === 'UNHANDLED'" color="red" outlined>
            {{ $t('SUPPORT.TICKET.STATUSES.UNHANDLED') }}
          </v-chip>

          <v-chip style="font-size: 10px;" v-if="row.item.status === 'PENDING_SEND'" color="blue" outlined>
            {{ $t('SUPPORT.TICKET.STATUSES.PENDING_SEND') }}
          </v-chip>

          <v-chip style="font-size: 10px;" v-if="row.item.status === 'UNREAD'" color="red" outlined>
            {{ $t('SUPPORT.TICKET.STATUSES.UNREAD') }}
          </v-chip>

          <v-chip style="font-size: 10px;" v-if="row.item.status === 'REPLIED'" color="green" outlined>
            {{ $t('SUPPORT.TICKET.STATUSES.REPLIED') }}
          </v-chip>

          <v-chip style="font-size: 10px;" v-if="row.item.status === 'DELETED'" color="green" outlined>
            {{ $t('SUPPORT.TICKET.STATUSES.DELETED') }}
          </v-chip>


        </template>
        <template #cell(action)='row'>
          <div class='d-flex justify-content-end'>
            <a href="#" class="btn btn-icon btn-light btn-sm mx-3" @click.prevent="select_billing_clicked(row.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">

                <inline-svg src="/assets/svg/Write.svg"></inline-svg>

              </span>
            </a>
            <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_billing_clicked(row.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">

                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>

              </span>
            </a>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'CompanyBillingTable',
  mixins: [ toasts ],
  props: ['items'],
  emits: ['create_company_billing_clicked', 'select_company_billing_clicked', 'delete_company_billing_clicked'],
  components: {

  },
  methods: {
    create_billing_clicked() {
      this.$emit('create_company_billing_clicked');
    },
    select_billing_clicked(item) {
      this.$emit('select_company_billing_clicked', item);
    },
    delete_billing_clicked(item) {
      this.$emit('delete_company_billing_clicked', item);
    },

    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.unread) {
          return 'font-weight-bolder';
        }

      }
      return null;
    }

  },
  data() {
    return {
      fields: [
        {
          key: 'created_at',
          label: this.$t('SUPPORT.TICKET.CREATED_AT'),
          sortable: true,
          thClass: 'td-short',
          tdClass: 'td-short align-middle'
        },
        {
          key: 'name',
          label: this.$t('SUPPORT.TICKET.NAME'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle td-ellipsis-wide '
        },
        {
          key: 'status',
          label: this.$t('SUPPORT.TICKET.STATUS'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'assigned_user',
          label: this.$t('SUPPORT.TICKET.ASSIGNED_USER'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle',
          formatter: (_, __, item) => {
            return item.assigned_user_id === null ? this.$t('SUPPORT.TICKET.UNASSIGNED') : item.assigned_user.email;
          }
        },
        {
          key: 'company.name',
          label: this.$t('COMPANY.COMPANY'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle td-ellipsis'
        },
        {
          key: 'action',
          label: '',
          thClass: 'w-110px',
          tdClass: 'align-middle'
        },
      ],
      list: [

      ]
    };
  }
};
</script>

<style lang="css" scoped>
:deep .td-ellipsis {
  max-width: 120px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  word-break: break-all;
}

:deep .td-ellipsis-wide {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
</style>
