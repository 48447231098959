<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <!-- Add membership begin -->
      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('COMPANY_BILLING.CREATED_AT')">

              <b-form-input :disabled="true" v-model="local_item.created_at" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('COMPANY_BILLING.UPDATED_AT')">

              <b-form-input :disabled="true" v-model="local_item.updated_at" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('COMPANY_BILLING.TITLE')">

              <b-form-input v-model="local_item.title" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('COMPANY_BILLING.STATUS')">

              <b-form-select
                v-model="local_item.status"
                :options="status_options"
                :state="validate_state({ dirty: v$['local_item']['status'].$dirty, error: v$['local_item']['status'].$error })">
              </b-form-select>


            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('COMPANY_BILLING.BILLING_SERVICE')">

              <b-form-select
                v-model="local_item.service"
                :options="billing_service_options"
                :state="validate_state({ dirty: v$['local_item']['service'].$dirty, error: v$['local_item']['service'].$error })">
              </b-form-select>


            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMPANY_BILLING.FROM_DATE')">

              <memlist-date-picker
                v-model="local_item.from_date"
              >
              </memlist-date-picker>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMPANY_BILLING.TO_DATE')">

              <memlist-date-picker
                v-model="local_item.to_date"
              >
              </memlist-date-picker>

            </b-form-group>
          </b-col>
        </b-row>


        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMPANY_BILLING.SCHEDULED_AT')">

              <memlist-date-picker
                v-model="local_item.scheduled_at"
              >
              </memlist-date-picker>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMPANY_BILLING.DUE_DATE')">

              <memlist-date-picker
                v-model="local_item.due_date"
              >
              </memlist-date-picker>

            </b-form-group>
          </b-col>
        </b-row>



        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('COMPANY_BILLING.DESCRIPTION')">

              <b-textarea rows="8" v-model="local_item.description" />

            </b-form-group>
          </b-col>
        </b-row>


        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>

            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('cancel')" ref="saveButton" />
          </b-col>
        </b-row>
      </b-form>


    </div>
  </div>
</template>


<script>
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'CompanyBillingForm',

  props: ['item'],
  emits: ['created', 'updated', 'save_data', 'cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },

  // --- begin vuelidate --- //
  setup() {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      local_item: {
        company_id: { required },
        status: { required },
        service: { required },
      }
    }
  },

  // -- end vuelidate -- //

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    company_options() {
      if (!this.companies) {
        return [];
      }

      const comps = this.companies.map((item) => ({ text: (item.shortname && item.shortname.length > 0 ? item.shortname : item.name), value: item.company_id }));

      comps.sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }

        return 1;
      });

      return comps;
    }
  },


  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

  },

  methods: {


    validate_state(state) {
      return state.dirty ? !state.error : null;
    },

    async on_submit() {

      if (!this.local_item.id) {
        await this.create_company_billing();
      }
      else {
        await this.update_company_billing();
      }

      this.$refs['saveButton'].stop();
    },

    async save_data() {

      this.$emit('save_data', this.local_item);

    },

    async create_company_billing() {
      try {
        const res = await axios.post(`/company_billing`, this.local_item);

        if (res.status === 201) {
          this.local_item = { ...res.data };

          this.$emit('created', this.local_item);

          return;
        }

      }
      catch (err) {
        console.error('create_company_billing error', err);
      }
    },

    async update_company_billing() {
      try {
        const res = await axios.put(`/company_billing/${this.local_item.id}`, this.local_item);

        if (res.status === 204) {
          this.$emit('updated', this.local_item);

          return;
        }

      }
      catch (err) {
        console.error('update_company_billing error', err);
      }
    }

  },


  data() {
    return {

      local_item: {},

      status_options: [
        { value: 'SCHEDULED', text: this.$t('COMPANY_BILLING.STATUSES.SCHEDULED') },
        { value: 'SENT', text: this.$t('COMPANY_BILLING.STATUSES.SENT') },
        { value: 'LATE', text: this.$t('COMPANY_BILLING.STATUSES.LATE') },
        { value: 'PAID_FULL', text: this.$t('COMPANY_BILLING.STATUSES.PAID_FULL') },
        { value: 'PAID_PARTIAL', text: this.$t('COMPANY_BILLING.STATUSES.PAID_PARTIAL') },
        { value: 'DISPUTE', text: this.$t('COMPANY_BILLING.STATUSES.DISPUTE') },
        { value: 'REFUNDED', text: this.$t('COMPANY_BILLING.STATUSES.REFUNDED') },
      ],

      billing_service_options: [
        { value: 'MEMLIST', text: this.$t('COMPANY_BILLING.BILLING_SERVICES.MEMLIST') },
        { value: 'SMS_USAGE', text: this.$t('COMPANY_BILLING.BILLING_SERVICES.SMS_USAGE') },
        { value: 'SMS_PREPURCHASE', text: this.$t('COMPANY_BILLING.BILLING_SERVICES.SMS_PREPURCHASE') },
        { value: 'SPAR_USAGE', text: this.$t('COMPANY_BILLING.BILLING_SERVICES.SPAR_USAGE') },
        { value: 'SPAR_PREPURCHASE', text: this.$t('COMPANY_BILLING.BILLING_SERVICES.SPAR_PREPURCHASE') },
        { value: 'SUPPORT', text: this.$t('COMPANY_BILLING.BILLING_SERVICES.SUPPORT') },
        { value: 'MEMBERCARD', text: this.$t('COMPANY_BILLING.BILLING_SERVICES.MEMBERCARD') },
        { value: 'DEVELOPMENT', text: this.$t('COMPANY_BILLING.BILLING_SERVICES.DEVELOPMENT') },

      ],
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
